// components/PostJob.jsx
import React, { useState } from 'react';
import axios from 'axios';

const PostJob = () => {
  const [job, setJob] = useState({
    title: '',
    description: '',
    location: '',
    numberOfApplicants: 1,
    salaryPerMonth: 0,
    skills: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJob((prevJob) => ({
      ...prevJob,
      [name]: name === 'skills' ? value.split(',').map((skill) => skill.trim()) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3000/postJob', job);
      console.log(response.data);
    } catch (error) {
      console.error('Error posting job:', error.message);
    }
  };

  return (
    <div>
      <h2>Post a Job</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input type="text" name="title" value={job.title} onChange={handleChange} />
        </label>
        <br />
        <label>
          Description:
          <textarea name="description" value={job.description} onChange={handleChange} />
        </label>
        <br />
        <label>
          Location:
          <input type="text" name="location" value={job.location} onChange={handleChange} />
        </label>
        <br />
        <label>
          Number of Applicants:
          <input type="number" name="numberOfApplicants" value={job.numberOfApplicants} onChange={handleChange} />
        </label>
        <br />
        <label>
          Salary per Month:
          <input type="number" name="salaryPerMonth" value={job.salaryPerMonth} onChange={handleChange} />
        </label>
        <br />
        <label>
          Skills (comma-separated):
          <input type="text" name="skills" value={job.skills} onChange={handleChange} />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PostJob;
