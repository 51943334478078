import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ViewJob.css';

const ViewJob = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('http://localhost:3000/viewJobs');
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error.message);
      }
    };

    fetchJobs();
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Job Listings</h2>
      {jobs.map((job) => (
        <div key={job._id} className="card mb-4">
          <div className="card-body">
            <h3 className="card-title">{job.title}</h3>
            <p className="card-text">{job.description}</p>
            <p className="card-text"><strong>Location:</strong> {job.location}</p>
            <p className="card-text"><strong>Number of Applicants Required:</strong> {job.numberOfApplicants}</p>
            <p className="card-text"><strong>Salary per Month:</strong> ${job.salaryPerMonth}</p>
            <p className="card-text"><strong>Skills:</strong> {job.skills.join(', ')}</p>
            <p className="card-text"><strong>Date Posted:</strong> {new Date(job.datePosted).toLocaleDateString()}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ViewJob;
