import React from 'react';
import './TalentCommunity.css';

function TalentCommunity() {
  return (
    <div className='TalentCommunity'>
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="card mt-5">
            <div className="card-header">
              <h3 className="text-center">Let's Connect - Talent Community</h3>
            </div>
            <div className="card-body">
              <div className="row">
                {/* Left Column */}
                <div className="col-md-6">
                  <p>
                    We are always looking to meet people who share our passion for connecting – to opportunity, to each other, and to the world. By joining our Talent Community, we’ll send you periodic updates on events in your area, insider news, career advice, and more. Our recruiters may even reach out about new opportunities to join Delta or one of our subsidiaries.
                  </p>
                  <p>
                    <strong>Privacy & Data Protection</strong>
                    <br />
                    Delta is committed to privacy and respecting the rights of those whose personal data we collect and use. Trust in our brand and how we treat the individuals we interact with is the core of our business. By subscribing to our Talent Network, I certify that I have reviewed and understand the Delta Privacy and Data Protection Recruitment Notice. I acknowledge and agree that Delta Air Lines may collect, use, transfer, and disclose my personal information as described in our Recruitment Notice.
                  </p>
                </div>

                {/* Right Column */}
                <div className="col-md-6">
                  {/* Form */}
                  <form>
                    {/* Personal Information */}
                    <div className="form-row">
                      <div className="form-group col-md-10">
                        <label htmlFor="firstName">First Name *</label>
                        <input type="text" className="form-control" id="firstName" />
                      </div>
                      <div className="form-group col-md-10">
                        <label htmlFor="lastName">Last Name *</label>
                        <input type="text" className="form-control" id="lastName" />
                      </div>
                    </div>

                    {/* Email and Job Title */}
                    <div className="form-row">
                      <div className="form-group col-md-10">
                        <label htmlFor="email">Email *</label>
                        <input type="email" className="form-control" id="email" />
                      </div>
                      <div className="form-group col-md-10">
                        <label htmlFor="jobTitle">Current Job Title *</label>
                        <input type="text" className="form-control" id="jobTitle" />
                      </div>
                    </div>

                    {/* Phone, LinkedIn, and Relocation */}
                    <div className="form-row">
                      <div className="form-group col-md-10">
                        <label htmlFor="phone">Phone Number</label>
                        <input type="text" className="form-control" id="phone" />
                      </div>
                      <div className="form-group col-md-10">
                        <label htmlFor="linkedin">LinkedIn Profile</label>
                        <input type="text" className="form-control" id="linkedin" />
                      </div>
                    </div>

                    {/* Willing to Relocate */}
                    <div className="form-group">
                      <label>Willing to relocate?</label>
                      <select className="form-control">
                        <option>Select an option</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>

                    {/* Areas of Interest */}
                    <div className="form-group">
                      <label>Area(s) of Interest *</label>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="area1" />
                        <label className="form-check-label" htmlFor="area1">Add Another</label>
                      </div>
                    </div>

                    {/* Locations of Interest */}
                    <div className="form-group">
                      <label>Location(s) of Interest</label>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Country *</label>
                          <select className="form-control">
                            <option>Select an option</option>
                            {/* Add country options */}
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label>City *</label>
                          <select className="form-control">
                            <option>Select an option</option>
                            {/* Add city options */}
                          </select>
                        </div>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="location1" />
                        <label className="form-check-label" htmlFor="location1">Add Another</label>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <button type="submit" className="btn btn-primary">SUBMIT</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TalentCommunity;
