import React from 'react';
import { Link } from 'react-router-dom';
import './EEOAccommodation.css'; // Import your custom CSS file

function EEOAccommodation() {
  return (
    <div className="eeo-container">
      <h1>Delta's EEO and Accommodation Policies</h1>

      <p><strong>Equal Employment Opportunity (EEO) (Title)</strong></p>
      <p>
        As an equal opportunity employer including Disability/Veterans, Delta Air Lines, Inc., and our wholly owned subsidiaries, recognizes that our strength lies in our people. We are committed to diversity. Please click{' '}
        <Link to="#" className="eeo-link" target="_blank" rel="noopener noreferrer">
          here
        </Link>
        {' '}to view applicable federal, state, and local law compliance postings.
      </p>

      <ul>
        <li>Know Your Rights: Workplace Discrimination is Illegal</li>
        <li>Polygraph Protection Act</li>
        <li>FMLA</li>
      </ul>

      <p><strong>Accommodation for Applicants (Title)</strong></p>
      <p>
        Delta Air Lines, Inc. is an Equal Employment Opportunity/Affirmative Action employer and provides reasonable accommodation for qualified individuals. Supporting medical or religious documentation will be required where applicable. If you are a qualified individual, you may request a reasonable accommodation at any point in the selection process if you are unable or limited in your ability to access job openings through this site, apply for jobs through Delta's online system, or require accommodation related to compliance with conditional job offer requirements. Please email us at{' '}
        <Link to="mailto:CandidateAccommodation@delta.com" className="eeo-link">
          CandidateAccommodation@delta.com
        </Link>
        {' '}or alternatively send a fax to 404-677-3851. To ensure a quick response to your request, please use the words "Accommodation request" as the subject line of your email or as the title of your fax document.
      </p>

      <p><strong>E-Verify (Title)</strong></p>
      <p>
        Delta Air Lines, Inc. and our wholly owned subsidiaries participate in the Electronic Employment Eligibility Verification Program.
      </p>

      <ul>
        <li><Link to="#" className="eeo-link" target="_blank" rel="noopener noreferrer">E-Verify English and Spanish (PDF)</Link></li>
        <li><Link to="#" className="eeo-link" target="_blank" rel="noopener noreferrer">Right to Work English and Spanish (PDF)</Link></li>
      </ul>

      <p><strong>Pay Transparency Nondiscrimination Provision (Title)</strong></p>
      <p>
        The contractor will not discharge or in any other manner discriminate against employees or applicants because they have inquired about, discussed, or disclosed their own pay or the pay of another employee or applicant. However, employees who have access to the compensation information of other employees or applicants as a part of their essential job functions cannot disclose the pay of other employees or applicants to individuals who do not otherwise have access to compensation information, unless the disclosure is (a) in response to a formal complaint or charge, (b) in furtherance of an investigation, proceeding, hearing, or action, including an investigation conducted by the employer, or (c) consistent with the contractor’s legal duty to furnish information. 41 CFR 60-1.35(c).
      </p>

      <p><strong>Pay Philosophy (Title)</strong></p>
      <p>
        Delta’s competitive base pay is one piece of a larger total compensation pie that includes programs like profit sharing, shared rewards, employee benefits, 401(k) contributions, travel privileges, and great opportunities for career growth and development. Together these elements serve as a key piece of what makes Delta a great place to work.
      </p>

      <p>
        All hourly rates for frontline employees are set based on a pay scale that is consistent for all employees in that job. Depending on the job, those pay scales escalate each year (or more frequently) up to a top of scale rate anywhere between year 6 and 12. All current employees have access to the full pay scales that are published on Deltanet, our internal intranet site. To the extent that a local minimum wage is higher than Delta’s pay rates, the local minimum wage rate is paid.
      </p>

      <p>
        All base pay rates, either hourly or annual, for merit employees are set based on a combination of internal and external experience, market competitiveness and individual performance.
      </p>

      <p><strong>Benefit Offered (Title)</strong></p>
      <p>
        Delta offers comprehensive benefits packages to meet the needs and wellbeing of our employees and their families. For eligible U.S. based employees, our benefits include medical, dental and vision coverage for employees and eligible dependents, short- and long-term disability, holidays, paid personal time off, vacation, non-revenue travel privileges, 401(k), profit sharing, and much more.
      </p>

      <p>
        All seasonal employees are offered non-revenue travel privileges, 401(k), profit sharing, and much more.
      </p>

      <p><strong>Privacy & Data Protection (Title)</strong></p>
      <p>
        Delta is committed to privacy and respecting the rights of those whose personal data we collect and use. Trust in our brand and how we treat the individuals we interact with is the core of our business. Please click to review our{' '}
        <Link to="#" className="eeo-link" target="_blank" rel="noopener noreferrer">
          Recruitment Notice
        </Link>
        .
      </p>

      <p><strong>Google Drive Services (Title)</strong></p>
      <p>
        You may wish to share personal information pertinent to your job application with Delta through Apply with Google portal using Google Drive Services. To utilize the Google features, you will be prompted to grant permissions within the platform, as you choose. For example, you will need to allow account login and publishing permissions. If you choose to interact with us using Google Drive Services, you understand that you are responsible for reviewing and understanding the terms and conditions, information security practices, and privacy policy applicable to Google, and that Delta is not responsible for how Google Drive Services use, share, or protect your information. The activity on Google Drive Services, and the data processed, stored, and provided on it is governed by the terms and conditions, information security practices, and privacy policy of Google.
      </p>
    </div>
  );
}

export default EEOAccommodation;
