import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-light py-3 ">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p>Delta Air Lines is an equal opportunity employer including disability/veteran.</p>
            <p>
              Delta Air Lines, Inc. is an Equal Employment Opportunity / Affirmative Action employer and provides
              reasonable accommodation in its application and selection process for qualified individuals, including
              accommodations related to compliance with conditional job offer requirements. Supporting medical or religious
              documentation will be required where applicable. To request a reasonable accommodation, please click{' '}
              <Link to="/eeo-accommodations">here</Link>.
            </p>

          </div>
          <div className="col-md-6">
            <p>
              Delta is proud to offer comprehensive benefits packages to meet the needs and wellbeing of our employees
              and their families. To learn more about our benefits, pay philosophy and pay rates, please click{' '}
              <Link to="/benefits">here</Link>.
            </p>
          </div>
        </div>
        <div className='copyright'><p >&copy;2023 Delta Air Lines, Inc.</p></div>

      </div>
    </footer>
  );
};

export default Footer;
