// ImageContext.js

import React, { createContext, useContext, useState } from 'react';

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [passportFront, setPassportFront] = useState(null);
  const [passportBack, setPassportBack] = useState(null);

  const setImages = (front, back) => {
    setPassportFront(front);
    setPassportBack(back);
  };

  const values = {
    passportFront,
    passportBack,
    setImages,
  };

  return <ImageContext.Provider value={values}>{children}</ImageContext.Provider>;
};

export const useImage = () => {
  const context = useContext(ImageContext);
  if (!context) {
    throw new Error('useImage must be used within an ImageProvider');
  }
  return context;
};
