import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './Homepage.css'; // Import the external CSS file
import { Link } from 'react-router-dom';

function Homepage() {
  const [textIndex, setTextIndex] = useState(0);
  const texts = ['Welcome to our website!', 'Register to get started.', 'Login if you already have an account.', 'Explore the amazing features.'];
  
  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <div className="homepage">
      <Container fluid>
        <Row>
          <Col md={8} className="left-side">
            <div className="text-container">
              <h1>{texts[textIndex]}</h1>
              <p className="lead">Discover a new world of possibilities with our platform.</p>
              <div className="button-container">
              <Link to="/search-jobs">
                <Button variant="info" size="lg" block>
                  Explore
                </Button>
              </Link>
            </div>
            </div>
          </Col>
          
            
          
        </Row>
      </Container>
    </div>
  );
}

export default Homepage;
