// PilotCareer.jsx

import React, { useState } from 'react';
import './PilotCareer.css'; // Assuming the CSS file is named PilotCareer.css

function PilotCareer() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    streetAddress: '',
    city: '',
    zipCode: '',
    state: '',
    militaryStatus: 'Select an option',
    airlineApps: 'Select an option',
    deltaPropel: 'Select an option',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form submitted:', formData);
  };

  return (
    <div className="pilot-career-container">
      <h1>Delta Pilot Talent Community</h1>
      <p>
        As COVID-19 continues to shape our world in unprecedented ways, the safety of our customers, employees and candidates is of the utmost importance. Please continue updating your application on AirlineApps to ensure you’re ready when the time comes to take flight.
      </p>
      <p>
        By joining our Talent Community, we’ll send you periodic updates on events in your area, insider news, career advice and more.
      </p>
      <p>
        Privacy & Data Protection Delta is committed to privacy and respecting the rights of those whose personal data we collect and use. Trust in our brand and how we treat the individuals we interact with is the core of our business. By subscribing to our Talent Network, I certify that I have reviewed and understand the Delta Privacy and Data Protection Recruitment Notice. I acknowledge and agree that Delta Air Lines may collect, use, transfer and disclose my personal information as described in our Recruitment Notice.
      </p>
      <form onSubmit={handleSubmit} className="pilot-form">
        <div className="column">
          <label htmlFor="firstName">First Name *</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />

          <label htmlFor="lastName">Last Name *</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />

          <label htmlFor="email">Email Address *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="country">Country *</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          />
        </div>

        <div className="column">
          <label htmlFor="streetAddress">Street Address *</label>
          <input
            type="text"
            id="streetAddress"
            name="streetAddress"
            value={formData.streetAddress}
            onChange={handleChange}
            required
          />

          <label htmlFor="city">City *</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />

          <label htmlFor="zipCode">Zip Code *</label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            required
          />

          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
          
          <div className='submitdesktop'><button type="submit" className="submit-button">SUBMIT</button></div>

        </div>

        <div className="column">
          <label htmlFor="militaryStatus">Are you or have you been a member of the US Military?</label>
          <select
            id="militaryStatus"
            name="militaryStatus"
            value={formData.militaryStatus}
            onChange={handleChange}
          >
            <option value="Select an option" disabled>Select an Option</option>
            <option value="Disabled Veteran">Disabled Veteran</option>
          </select>

          <label htmlFor="airlineApps">Do you have a current updated application on airlineapps?</label>
          <select
            id="airlineApps"
            name="airlineApps"
            value={formData.airlineApps}
            onChange={handleChange}
          >
            <option value="Select an option" disabled>Select an Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>

          <label htmlFor="deltaPropel">Do you have a current updated application with Delta’s Propel program?</label>
          <select
            id="deltaPropel"
            name="deltaPropel"
            value={formData.deltaPropel}
            onChange={handleChange}
          >
            <option value="Select an option" disabled>Select an Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className='submitmobile'><button type="submit" className="submit-button">SUBMIT</button></div>

      </form>
    </div>
  );
}

export default PilotCareer;
